import './index.scss'
import ImageContentBlock from "../image-content-block";

const HowItWorks = ( { title, items, smDevice, mdDevice }) => {

	return (
		<div className="how-it-works">
			<div className="container">
				<h2 className={`${smDevice ? 'title-24px' : 'title-32px'}`}>{title}</h2>
				<div className="how-it-works__content">
					{items.map((item, key) => {
						return (
								<ImageContentBlock {...item} key={key} smDevice={smDevice} mdDevice={mdDevice} />
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default HowItWorks