import './index.scss'
import Slider from 'react-slick'
import {useRef} from "react";

const CasesBenefitsItem = ({ item, mdDevice }) => {
	return (
			<div className="benefits__item" style={item?.style}>
				{item.image && (<div className="benefits__item-icon">
					<img src={item.image?.src} alt={item.image.alt} />
				</div>)}
				<div className={`benefits__item-title fw-500 dark ${mdDevice ? 'text-16px' : 'text-18px'}`}>{item.title}</div>
				<div className={`dark ${mdDevice ? 'text-14px' : 'text-16px'}`}>{item.text}</div>
			</div>
	)
}

const CasesBenefitsBlock = ({ items, gridConfig = {}, smDevice, mdDevice }) => {
	let sliderRef = useRef(null)
	const settings = {
		className: 'slider variable-width',
		infinite: true,
		arrows: false,
		dots: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		variableWidth: true,
		centerMode: false
	}

	const contentStyles = {
		display: 'grid',
		...gridConfig
	}
	return (
			<div className="benefits">
				<div className="container">
					{mdDevice ? (
						<Slider
							ref={slider => {
								sliderRef = slider;
							}}
							{...settings}
						>
							{items.map((item, index) => {
								return (
									<CasesBenefitsItem item={item} key={index} mdDevice={mdDevice} />
								)
							})}
						</Slider>
					) : (
						<div className="benefits__content" style={contentStyles}>
							{items.map((item, index) => (<CasesBenefitsItem item={item} key={index} mdDevice={mdDevice} />))}
						</div>
					)}
				</div>
			</div>
	)
}

export default CasesBenefitsBlock