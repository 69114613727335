import './index.scss'
import {useEffect, useState} from "react";
import sendIcon from './img/send.svg'

const ReadyPartners = () => {
	const [email, setEmail] = useState('')
	const [smDevice, setSmDevice] = useState(
			window.matchMedia('(max-width: 560px)').matches
	)

	useEffect(() => {
		window
				.matchMedia('(max-width: 560px)')
				.addEventListener('change', e => setSmDevice(e.matches))
	}, [])

	const bannerBg = smDevice ? require('./img/banner-mobile.png') : require('./img/banner.png')
	const scriptUrl = 'https://script.google.com/macros/s/AKfycbxbLFOKkHSzfguL2vDK149PgeJFxH9pVZ3ABFKZghDtbvcmDzzWKNNZN44xUdTX_m3M/exec'
	const handleSubmit = (e) => {
		e.preventDefault()
		if (!email) {
			return
		}
		const data = new FormData()
		data.append('email', email)


		fetch(scriptUrl, {
			method: 'POST',
			body: data
		})
				// .then((res) => onSuccess(res))
				// .catch((e) => onError(e))
				// .finally(() => setLoading(false))

	}
	return (
			<div className="ready-partners">
				<div className="container">
					<div className="ready-partners__banner">
						<img
								src={bannerBg}
								srcSet={`${require(`./img/banner@2x.png`)} 2x, ${require(`./img/banner@3x.png`)} 3x`}
								alt="banner"
						/>
						<div className="ready-partners__content">
							<h2 className="ready-partners__title">Готові почати та приєднатися до партнерки Dubidoc?</h2>
							<p className="ready-partners__text">Пам’ятайте, що час — це гроші! Допоможіть своїм клієнтам заощадити час і отримуйте винагороду за це з Dubidoc</p>
							<form onSubmit={handleSubmit} className="ready-partners__form">
								<div className="ready-partners__input-wrapper">
									<input
											value={email}
											onChange={(e) => setEmail(e.target.value)}
											type="text"
											name="email"
											className="ready-partners__input"
											placeholder="Email"
									/>
									<button className="ready-partners__input-button" type="submit">
										<img src={sendIcon} alt="send icon"/>
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
	)
}

export default ReadyPartners