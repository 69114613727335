import './index.scss'

const CasesTemplatesAdvantages = ({ buttonHref, title, smDevice }) => {
	return (
			<div className="templates-advantages">
				<div className="container">
					<div className="templates-advantages__wrapper">
						<h2 className={`${smDevice ? 'title-24px' : 'title-32px'}`}>{title}</h2>
						<div className="templates-advantages__content">
							<div className="templates-advantages__left">
								<img src={require('./img/office.png')} alt="Working space"/>
							</div>
							<div className="templates-advantages__right">
								<div className="templates-advantages__item">
									<h4 className={`mb-16 ${smDevice ? 'title-20px' : 'title-24px'}`}>- автоматизувати процеси</h4>
									<p className={`${smDevice ? 'text-14px' : 'text-16px'}`}>
										Створення динамічних шаблонів документів для компаній, що співпрацюють із
										ФОПами, може значно спростити процеси укладання договорів та управління документацією
									</p>
								</div>
								<div className="templates-advantages__item">
									<h4 className={`mb-16 ${smDevice ? 'title-20px' : 'title-24px'}`}>- зменшити час на оформлення документів</h4>
									<p className={`${smDevice ? 'text-14px' : 'text-16px'}`}>
										У нашому інтуїтивно зрозумілому редакторі ви можете легко додати текст, підписи, галочки та інші необхідні поля
									</p>
								</div>
								<a className="button-main" href={buttonHref} target="_blank">Створіть шаблон безкоштовно</a>
							</div>
						</div>
					</div>
				</div>
			</div>
	)
}

export default CasesTemplatesAdvantages