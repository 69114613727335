import './index.scss'

const Auditory = ({ buttonHref }) => {
	return (
		<div className="auditory">
			<div className="container">
				<div className="auditory__banner">
					<div className="auditory__images">
						<img
								src={require('./img/1.png')}
								className="auditory__img auditory__img--1"
								alt=""
						/>
						<img
								src={require('./img/2.png')}
								className="auditory__img auditory__img--2"
								alt=""
						/>
						<img
								src={require('./img/3.png')}
								className="auditory__img auditory__img--3"
								alt=""
						/>
						<img
								src={require('./img/4.png')}
								className="auditory__img auditory__img--4"
								alt=""
						/>
						<img
								src={require('./img/5.png')}
								className="auditory__img auditory__img--5"
								alt=""
						/>
						<img
								src={require('./img/6.png')}
								className="auditory__img auditory__img--6"
								alt=""
						/>
						<img
								src={require('./img/7.png')}
								className="auditory__img auditory__img--7"
								alt=""
						/>
					</div>
					<div className="auditory__content">
						<h2 className="auditory__title">У вас є велика аудиторія? Використайте її на повну!</h2>
						<p className="auditory__text">Партнерство, яке приносить реальний прибуток! Приєднуйтесь до Dubidoc і заробляйте на простих рішеннях для бізнесу</p>
						<a href={buttonHref} target="_blank" className="button-main">Розпочати зараз</a>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Auditory