import './index.scss'

const CasesTemplatesBlock = ({ buttonHref, smDevice }) => {
	return (
		<div className="cases-templates">
			<div className="container">
				<div className="cases-templates__content-wrapper">
					<div className="cases-templates__content">
						<h2 className={`${smDevice ? 'title-24px' : 'title-32px'}`}>Створіть безкоштовно свій перший динамічний шаблон документа</h2>
						<a className="button-main" href={buttonHref} target="_blank">Розпочати зараз</a>
					</div>
					<div className="cases-templates__image">
						<img src={require('./img/phone-templates-screen.png')} alt="Dubidoc templates page"/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CasesTemplatesBlock