import './index.scss'

const CasesTextBlock = ({ title, smDevice, mdDevice }) => {
	let titleClass = 'title-32px'
	if (mdDevice) {
		titleClass = 'title-24px'
	}
	if (smDevice) {
		titleClass = 'title-16px'
	}
	return (
			<div className="cases-text-block">
				<div className="container">
					<div className="cases-text-block__content">
						<div className="cases-text-block__icon">
							<img src={require('./img/phrase.png')} alt="Phrase icon"/>
						</div>
						<h3 className={titleClass}>{title}</h3>
					</div>
				</div>
			</div>
	)
}

export default CasesTextBlock