import './index.scss'
import {
	useEffect,
	useRef, useState
} from "react"
import Slider from 'react-slick'

const CooperationItem = ({ item }) => {
	return (
			<div className="cooperation__item">
				<img
						src={require(`./img/${item.img}.png`)}
						alt="Для кого підходить програма"
				/>
			</div>
	)
}

const Cooperation = () => {
	const [mdDevice, setMdDevice] = useState(
			window.matchMedia('(max-width: 1024px)').matches
	)
	let sliderRef = useRef(null)
	const settings = {
		className: 'slider variable-width',
		infinite: true,
		dots: true,
		arrows: false,
		slidesToShow: mdDevice ? 1 : 3,
		slidesToScroll: 1,
		variableWidth: true,
		centerMode: false
	}

	const items = [
		{
			img: 'cooperation-1',
		},
		{
			img: 'cooperation-2',
		},
		{
			img: 'cooperation-3',
		},
		{
			img: 'cooperation-4',
		},
		{
			img: 'cooperation-5',
		},
		{
			img: 'cooperation-6',
		},
		{
			img: 'cooperation-7',
		}
	]

	useEffect(() => {
		window
				.matchMedia('(max-width: 1024px) and (min-width: 768px)')
				.addEventListener('change', e => setMdDevice(e.matches))
	}, [])

	return (
			<div className="cooperation">
				<div className="container">
					<div className="cooperation__header">
						<h2 className="cooperation__title">Будемо раді співпрацювати</h2>
						<p className="cooperation__text">Для кого підходить дана партнерська програма? Відповідаємо 🥰</p>
					</div>
					<div className="cooperation__slider">
						<Slider
								ref={slider => {
									sliderRef = slider;
								}}
								{...settings}
						>
							{items.map((item, key) => {
								return (
										<CooperationItem
												key={key}
												item={item}
										/>
								)
							})}
						</Slider>
					</div>
				</div>
			</div>
	)
}

export default Cooperation