import './index.scss'

const IntroBlock = ({ buttonHref, title, subtitle, image, smDevice }) => {
	return (
		<div className="intro">
			<div className="container">
				<div className="intro__content-wrapper">
					<div className="intro__content">
						<h1 className={`intro__title mb-24 ${smDevice ? 'title-24px' : 'title-40px'}`} dangerouslySetInnerHTML={{ __html: title }} ></h1>
						{subtitle && (<p className={`${smDevice ? 'text-14px mb-32' : 'text-18px mb-48'}`}>{subtitle}</p>)}
						<a className="button-main" href={buttonHref} target="_blank">Розпочати зараз</a>
					</div>
					<div className="intro__image">
						<img src={image.src} alt={image.alt}/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default IntroBlock