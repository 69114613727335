import './index.scss'
import {HashLink as Link} from "react-router-hash-link";
import SupportButton from "../support-button";
const BurgerMenuOverlay = ({ buttonHref }) => {
	return (
			<div className="burger-menu-overlay">
				<nav className="nav">
					<Link to="/partners" className="nav__link lg">Партнерам</Link>
				</nav>
				<div className="burger-menu-overlay__footer">
					<SupportButton fullWidth={true}/>
					<a href={buttonHref} target="_blank" className="button-main" id="vhid">
						Вхід
					</a>
				</div>
			</div>
	)
}

export default BurgerMenuOverlay