import './index.scss'
import plus from "../../img/plus.svg";

const FaqAccordionItem = ({ item }) => {
	const toggleItem = (e) => {
		const faqItem = e.target.closest('.faq-accordion__item')
		const items = document.querySelectorAll('.faq-accordion__item')
		for (const item of items) {
			if (item.classList.contains('active') && item !== faqItem) {
				item.classList.remove('active')
			}
		}
		if (faqItem.classList.contains('active')) {
			faqItem.classList.remove('active')
		} else {
			faqItem.classList.add('active')
		}
	}

	return (
			<div className="faq-accordion__item">
				<div className="faq-accordion__item-header" onClick={toggleItem}>
					<div className="faq-accordion__item-label">{item.question}</div>
					<img src={plus} alt="" className="faq-accordion__toggle-icon"/>
				</div>
				<div className="faq-accordion__item-content">
				<p className="faq-accordion__item-text">{item.answer}</p>
				</div>
			</div>
	)
}

const FaqAccordion = ({items = []}) => {
	return (
			<div className="faq-accordion">
				{items.map((item, key) => (<FaqAccordionItem item={item} key={key} />))}
		</div>
	)
}

export default FaqAccordion