import './index.scss'
import FaqAccordion from "../faq-accordion"

const FaqPartners = () => {
	const items = [
		{
			question: 'Хто такий партнер в рамках програми?',
			answer: 'Партнер Dubidoc — це друг, який допомагає бізнесам переходити на сучасний та зручний електронний документообіг. Ви рекомендуєте наш сервіс своїм клієнтам, колегам або знайомим і водночас отримуєте приємний бонус за свою рекомендацію.'
		},
		{
			question: 'Які умови нарахування винагороди?',
			answer: ' *Наші партнери отримують: Разова винагорода: 20 грн за кожного активного клієнта, який: зареєструється за реферальним посиланням, створить документ, відправить та підпише його. Сервіс безкоштовний.'
		},
		{
			question: 'Які документи потрібні для початку?',
			answer: ' Жодних. Ми надамо всю необхідну інформацію та проконсультуємо вас для успішного старту роботи.'
		},
		{
			question: 'Як я отримую свої виплати?',
			answer: ' Коли ви залогінені в своєму кабінеті партнера, ви маєте одноразово підписати договір в розділі налаштування, натискаючи на вивід формується акт, далі підписує акт, і до 5 робочих днів ви отримуєте виплату комісійної винагороди по своїм рефералам.'
		}
	]
	return (
		<div className="faq-partners">
			<div className="container">
				<h2 className="faq-partners__title">Часті запитання і одразу відповіді на них</h2>
				<div className="faq-partners__faq-wrapper">
					<FaqAccordion items={items}/>
				</div>
			</div>
		</div>
	)
}

export default FaqPartners