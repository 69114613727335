import './index.scss'
import dots from './img/dots.svg'
import close from './img/close.svg'

const BurgerMenu = ({showBurgerMenu, setShowBurgerMenu }) => {
	const toggleMenu = () => {
		if (showBurgerMenu) {
			setShowBurgerMenu(false)
			document.querySelector('body').style.overflow = 'auto'
		} else {
			setShowBurgerMenu(true)
			document.querySelector('body').style.overflow = 'hidden'
		}
	}
	return (
		<button className="burger-menu" onClick={toggleMenu}>
			<img src={showBurgerMenu ? close : dots} alt="burger toggler"/>
		</button>
	)
}

export default BurgerMenu