import './index.scss'
import { HashLink as Link } from 'react-router-hash-link'
import logo from '../../img/logo.svg'
import Socials from "../socials";

const Footer = () => {
  return (
    <footer className="footer" id="footer">
      <div className="container">
        <div className="footer__content">
          <div className="footer__top">
            <Link to="/">
              <img
                  className="footer__logo"
                  alt="Logo"
                  src={logo}
              />
            </Link>
          </div>
          <div className="footer__bottom">
            <Socials/>
            <Link to="/public-offer/" className="footer__link">
              Публічна оферта
            </Link>
            <Link to="/privacy-policy/" className="footer__link">
              Політика конфіденційності
            </Link>
            <p>
              © Всі права захищені «Dubidoc» {new Date().getFullYear()}
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer