import './index.scss'
import logo from '../../img/logo.svg'
import SupportButton from '../support-button'
import { HashLink as Link } from 'react-router-hash-link'
import {useEffect, useState} from "react";
import BurgerMenu from "../burger-menu";
import BurgerMenuOverlay from "../burger-menu-overlay";

const Header = ({ buttonHref }) => {
  const [showBurgerMenu, setShowBurgerMenu] = useState(false)
  const [smDevice, setSmDevice] = useState(
      window.matchMedia('(max-width: 560px)').matches
  )

  useEffect(() => {
    window
        .matchMedia('(max-width: 560px)')
        .addEventListener('change', e => setSmDevice(e.matches))
  }, [])
  return (
    <header className="header" id="header">
      <div className="container">
        <div className="header__content">
          <div className="header__left">
            <Link to="/">
              <img
                  className="header__logo"
                  alt="Logo"
                  src={logo}
              />
            </Link>
            {/*{!smDevice && <nav className="nav">*/}
            {/*  <Link to="/partners" className="nav__link">Партнерам</Link>*/}
            {/*</nav>}*/}
          </div>
          {!smDevice && (<div className="header__btns">
            <SupportButton/>
            <a href={buttonHref} target="_blank" className="button-main" id="vhid">
              Вхід
            </a>
          </div>)}
          {smDevice && <BurgerMenu showBurgerMenu={showBurgerMenu} setShowBurgerMenu={setShowBurgerMenu} />}
          {showBurgerMenu && <BurgerMenuOverlay buttonHref={buttonHref} />}
        </div>
      </div>
    </header>
  )
}

export default Header