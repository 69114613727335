import './index.scss'
import {useEffect, useState} from "react";

const Banner = ({ buttonHref }) => {
	const [smDevice, setSmDevice] = useState(
			window.matchMedia('(max-width: 560px)').matches
	)

	useEffect(() => {
		window
				.matchMedia('(max-width: 560px)')
				.addEventListener('change', e => setSmDevice(e.matches))
	}, [])

	const bannerBg = smDevice ? require('./img/banner-mobile-bg.png') : require('./img/banner-bg.png')

	return (
		<div className="banner">
			<div className="container">
				<div className="banner__wrapper">
					<img
							src={bannerBg}
							srcSet={`${require('./img/banner-bg@2x.png')} 2x, ${require('./img/banner-bg@3x.png')} 3x`}
							alt="Banner"
					/>
					<div className="banner__content">
						<h2 className="banner__title">Приєднуйтесь до нашої спільноти партнерів!</h2>
						<p className="banner__text">Обмінюйтеся досвідом та ідеями. Додаткова цінність кожному партнеру
							гарантована</p>
						<a href={buttonHref} target="_blank" className="button-main">Стати партнером</a>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Banner