import './index.scss'

const Templates = ({ buttonHref }) => {
	return (
		<div className="templates">
			<div className="container">
				<div className="templates__content">
					<div className="templates__left">
						<div className="templates__chip">New</div>
						<div className="templates__title">Інтерактивні <br/> шаблони документів</div>
						<p className="templates__text">
							Створюйте документи з динамічними полями, які легко заповнити. Надсилайте їх через email, посилання або
							QR-код і одразу отримуйте заповнені й підписані документи. Зручно для вас і без зайвих кроків для ваших
							клієнтів, контрагентів, співробітників.
						</p>
						<a href={buttonHref} target="_blank" className="button-main" id="create-template">
							Створити шаблон зараз
						</a>
					</div>
					<div className="templates__right">
						<div className="templates__img-wrapper">
							<img
									src={require('./img/templates-document.png')}
									srcSet={`${require('./img/templates-document@2x.png')} 2x, ${require('./img/templates-document@3x.png')} 3x`}
									alt="Приклад документу з шаблонами"
									className="templates__template-img"
							/>
							<img
									src={require('./img/templates-fields.png')}
									srcSet={`${require('./img/templates-fields@2x.png')} 2x, ${require('./img/templates-fields@3x.png')} 3x`}
									alt="DocumentOptions"
									className="templates__template-options-img"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Templates