import Header from '../components/header'
import Footer from '../components/footer'
import AboutPartners from '../components/about-partners'
import Banner from '../components/banner'
import AdvantagesPartners from "../components/advantages-partners"
import Cooperation from "../components/cooperation"
import Auditory from "../components/auditory"
import HowItWorks from "../components/how-it-works"
import FaqPartners from '../components/faq-partners'
import ReadyPartners from "../components/ready-partners"
import {useEffect, useState} from "react";

const Partners = () => {
	const href = new URL('https://partner.checkbox.ua/auth/login')
	const partnersLink = href.toString()
	const [smDevice, setSmDevice] = useState(
			window.matchMedia('(max-width: 560px)').matches
	)
	const [mdDevice, setMdDevice] = useState(
			window.matchMedia('(max-width: 960px)').matches
	)

	const howItWorksItems = [
		{
			title: '1. Увійдіть в свій кабінет або зареєструйтесь',
			text: 'Партнерський кабінет у нас спільний для checkbox та dubidoc, аби все було в одному місці. Зручно, чи не так?',
			image: {
				src: require('../components/how-it-works/img/login-screen.png'),
				alt: 'dubidoc login',
				styles: {
					alignItems: 'flex-end'
				}
			},
			contentFirst: !mdDevice
		},
		{
			title: '2. Заберіть своє унікальне реферальне посилання та діліться посиланням з потенційними клієнтами',
			image: {
				src: require('../components/how-it-works/img/referal.png'),
				alt: 'referal link'
			},
			contentFirst: false
		},
		{
			title: '3. Отримуйте винагороду за кожного нового користувача*',
			image: {
				src: require('../components/how-it-works/img/platform.png'),
				alt: 'platform'
			},
			contentFirst: !mdDevice
		}
	]

	useEffect(() => {
		window
				.matchMedia('(max-width: 560px)')
				.addEventListener('change', e => setSmDevice(e.matches))
		window
				.matchMedia('(max-width: 960px)')
				.addEventListener('change', e => setMdDevice(e.matches))
	}, [])

	return (
		<div className="App">
			<Header />
			<main>
				<AboutPartners buttonHref={partnersLink}/>
				<Banner buttonHref={partnersLink}/>
				<AdvantagesPartners buttonHref={partnersLink}/>
				<Cooperation/>
				<Auditory buttonHref={partnersLink}/>
				<HowItWorks title="Як працює наша партнерська програма?" items={howItWorksItems}/>
				<FaqPartners/>
				<ReadyPartners/>
			</main>
			<Footer />
		</div>
	)
}

export default Partners